<template>
  <div>
    <slot name="header" :trainingCourses="trainingCourses"></slot>
    <div v-if="$online">
      <v-list class="bg-main pt-0" flat two-line>
        <div class="transparent mx-1" v-if="showFilters">
          <v-row class="px-4">
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-5"
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                v-model="trainingCourseSearch"
                @input="searchTrainingCourses"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0">
              <div class="text-center tab-wrap pb-3 px-1">
                <v-btn
                  class="mr-1"
                  small
                  tile
                  @click="
                    availableFilter = false;
                    enrolledFilter = false;
                  "
                  :style="
                    !availableFilter && !enrolledFilter ? 'border: 1px solid #468ffb' : 'border: 1px solid transparent'
                  "
                >
                  {{ $t("c_training.t_all_courses") }}</v-btn
                >
                <v-btn
                  class="mx-1"
                  small
                  tile
                  @click="
                    availableFilter = !availableFilter;
                    enrolledFilter = false;
                  "
                  :style="availableFilter ? 'border: 1px solid #468ffb' : 'border: 1px solid transparent'"
                >
                  {{ $t("c_training.t_available") }}</v-btn
                >
                <v-btn
                  class="ml-1"
                  small
                  tile
                  @click="
                    enrolledFilter = !enrolledFilter;
                    availableFilter = false;
                  "
                  :style="enrolledFilter ? 'border: 1px solid #468ffb' : 'brder: 1px solid transparent'"
                >
                  {{ $t("c_training.t_enrolled") }}</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>

        <div>
          <div v-for="(item, idx) in courses" :key="idx">
            <div class="form-item-wrap">
              <EnrollmentListItem
                v-if="item.enrolledOn"
                ref="enrollmentListItem"
                :item="item"
                :parent="'trainingCourses'"
              />
              <TrainingListItem
                v-if="!item.enrolledOn"
                ref="trainingListItem"
                :item="item"
                :parent="'trainingCourses'"
              />
            </div>
          </div>
          <h4 class="pa-4" v-if="!courses?.length">No courses</h4>
        </div>
      </v-list>
      <slot name="loadmore">
        <v-btn
          v-if="!loading && showLoadMoreCourses && trainingCourses && trainingCourses.length > 0"
          class="mx-3 mb-3"
          @click="loadMoreCourses"
          >{{ $t("t_load_more") }}</v-btn
        >
      </slot>
    </div>
    <h4 class="pa-4" v-if="(!loading && trainingCourses.length < 1) || !$online">
      {{ $t("c_training.t_no_training_courses_available") }}
      {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
    </h4>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import TrainingListItem from "@/components/training/TrainingListItem.vue";
import EnrollmentListItem from "@/components/training/EnrollmentListItem.vue";
import debounce from "lodash/debounce";

export default {
  name: "TrainingCourseList",
  components: { TrainingListItem, EnrollmentListItem },
  props: {
    takeNum: {
      type: Number,
      default: 20,
    },
    parent: {
      type: String,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [Helpers],
  computed: {
    courses() {
      let courses = this.trainingCourses;
      if (this.availableFilter) {
        courses = this.trainingCourses.filter((course) => {
          return !course.enrolledOn;
        });
      }
      if (this.enrolledFilter) {
        courses = this.trainingCourses.filter((course) => {
          return course.enrolledOn;
        });
      }
      return courses;
    },
  },

  data() {
    return {
      allCourses: true,
      loading: false,
      trainingCourses: [],
      trainingCourseSearch: "",
      trainingCourseAscending: false,
      trainingCoursesSkipNum: 0,
      trainingCourseTakeNum: 20,
      showLoadMoreCourses: false,
      availableFilter: false,
      enrolledFilter: false,
    };
  },
  methods: {
    ...mapActions("training", [
      "getCourses",
      "downloadAsset",
      "createUserDownloadToken",
      "searchEnrollments",
      "getCourse",
    ]),

    async searchallCourses(reload = false) {
      if (!this.$online) {
        return;
      }
      if (reload) {
        this.trainingCoursesSkipNum = 0;
      }
      this.loading = true;
      this.showLoadBar();
      let trainingParams = {
        "paging.ascending": this.trainingCourseAscending,
        "paging.skipnum": this.trainingCoursesSkipNum,
        "paging.takenum": this.trainingCourseTakeNum,
      };
      if (this.trainingCourseSearch) {
        trainingParams.search = this.trainingCourseSearch;
      }
      trainingParams["paging.orderbyfield"] = "expiresOn";
      const res = await this.getCourses(trainingParams);
      const courses = res.data;
      courses.forEach(async (c) => {
        if (c.headerImageAssetId && c.headerImageAssetPublicId !== "00000000-0000-0000-0000-000000000000") {
          const url = await this.getImageAsset(c.headerImageAssetId, c.headerImageAssetPublicId);
          this.$set(c, "headerImageUrl", url);
        }
      });

      this.trainingCourses = [...courses];
      trainingParams["paging.orderbyfield"] = "enrolledOn";
      const r = await this.searchEnrollments(trainingParams);
      let rs = await r.data;
      const enrollments = rs.filter((e) => !e.endedOn);
      enrollments.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
      this.trainingCourses.forEach(async (course, index) => {
        course.dueDate = null;
        course.isEnrolled = false;
        enrollments.forEach((r) => {
          if (course.id === r.courseId) {
            r.dueDate = r.expiresOn;
            this.$set(r, "dueDate", r.expiresOn);
            this.$set(r, "isEnrolled", true);
            this.trainingCourses[index] = { ...course, ...r };
          }
        });
      });
      this.trainingCourses.sort((a, b) => b.isEnrolled - a.isEnrolled);
      this.loading = false;
      this.hideLoadBar();
    },

    loadMoreCourses() {
      this.trainingCoursesSkipNum = this.trainingCourses.length;
      this.searchallCourses();
    },

    searchTrainingCourses: debounce(function () {
      this.searchallCourses(true);
    }, 500),
  },
  created() {
    this.searchallCourses(true);
  },
};
</script>
