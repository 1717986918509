<template>
  <v-list-item
    :disabled="!$online"
    class="message-item rounded mx-2 pr-1 mb-2"
    :class="{ 'grey darken-4': $vuetify.theme.dark }"
    :key="item.messageId"
    @click.stop="showMessage(item)"
  >
    <v-icon v-if="item.companyWide" class="mr-3" large color="gray lighten-1">mdi-domain</v-icon>
    <v-icon v-else-if="item.systemNotice" class="mr-3" large color="gray lighten-1">mdi-broadcast</v-icon>
    <div v-else class="mr-4">
      <app-user-avatar
        :user="{
          profileImageAssetId: item.from?.profileImageAssetId
            ? item.from.profileImageAssetId
            : item.fromUserProfileImageAssetId,
          profileImageAssetKey:
            item.from?.profileImageAssetKey && item.from.profileImageAssetKey !== '00000000-0000-0000-0000-000000000000'
              ? item.from.profileImageAssetKey
              : item.fromUserProfileImageAssetKey,
          initials: item.from?.initials ? item.from.initials : item.fromUserInitials,
          userId: item.from?.id ? item.from.id : item.fromUserId,
        }"
        :size="32"
      />
    </div>
    <v-list-item-content>
      <v-list-item-subtitle v-if="item" class="text-caption">
        <span v-if="item.companyWide">{{ $t("c_messagelistitem.t_company_message_from") }} </span>
        <span v-else-if="item.systemNotice">{{ $t("c_messagelistitem.t_system_message") }} </span>
        <span> {{ item.fromUserFriendlyName }}</span>
      </v-list-item-subtitle>
      <v-list-item-title v-html="item.subject"></v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="mx-2 mr-4 mt-6" v-if="item.isNew">
      <v-scroll-x-transition>
        <v-badge v-if="showNew" color="orange darken-3" :content="$t('c_messagelistitem.t_new')"> </v-badge>
      </v-scroll-x-transition>
    </v-list-item-action>
    <v-list-item-action-text class="ml-2">{{ formatDateTimeContextual(item.sentOn) }} </v-list-item-action-text>
    <v-list-item-action class="ml-2">
      <v-icon small v-if="item.readOn" color="green lighten-1">mdi-check-circle</v-icon>
      <v-icon small v-else color="grey darken-1">mdi-checkbox-blank-circle</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import Helpers from "@/mixins/helpers";
import AppUserAvatar from "@/components/AppUserAvatar.vue";

export default {
  name: "MessageListItem",
  mixins: [Helpers],
  components: {
    AppUserAvatar,
  },
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
  data: () => ({
    showNew: true,
  }),
  methods: {
    showMessage(message) {
      this.$emit("showMessage", message);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showNew = false;
    }, 6000);
  },
};
</script>
